<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Lịch sử hoạt động hoa hồng AdHoc'">
          <template v-slot:preview>
            <div class="col-md-12 mb-5">
              <b-form>
                <b-row>
                  <b-col md="2" style="padding-left: 0px">
                    <b-input
                      v-model="rewardAdhocId"
                      placeholder="ID"
                      size="sm"
                    ></b-input>
                  </b-col>
                  <b-col md="3" style="padding-left: 0px">
                    <b-input
                      v-model="rewardAdHocName"
                      placeholder="Tên"
                      size="sm"
                    ></b-input>
                  </b-col>
                  <b-col md="3">
                    <b-button size="sm" variant="primary" @click="fetchHistory">
                      Tìm kiếm
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <b-table
              :items="histories"
              :fields="fields"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template v-slot:cell(action)="row">
                <span
                  v-text="getActionName(row.item.action)"
                  class="label font-weight-bold label-lg label-inline"
                  v-bind:class="{
                    'label-light-success': row.item.action === 'insert',
                    'label-light-warning': row.item.action === 'update',
                    'label-light-danger': row.item.action === 'delete',
                  }"
                  style="width: max-content"
                ></span>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="viewDetail(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon-eye icon-color"
                        ></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-if="totalPages"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchHistory"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import moment from 'moment';

export default {
  data() {
    return {
      histories: [],
      rewardAdhocId: null,
      rewardAdHocName: null,
      creatorName: null,
      loaderEnabled: false,
      onLoading: false,
      listAction: [
        {
          id: 'insert',
          name: 'Thêm mới',
        },
        {
          id: 'update',
          name: 'Cập nhật',
        },
        {
          id: 'delete',
          name: 'Xóa',
        },
      ],
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      page: 1,
      search: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'rewardAdhocId',
          label: 'ID',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'rewardAdhoc',
          label: 'Tên chương trình hoa hồng AdHoc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          formatter: (value) => {
            return value.name;
          },
        },
        {
          key: 'action',
          label: 'Loại thao tác',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'creator',
          label: 'Người thao tác',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'createdAt',
          label: 'Thời gian',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          formatter: (value) => {
            return moment(value).format('DD/MM/YYYY HH:mm:ss');
          },
        },
        { key: 'actions', label: '' },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Lịch sử hoạt động hoa hồng AdHoc' },
    ]);
  },
  created() {
    this.fetchHistory();
  },
  methods: {
    viewDetail(item) {
      this.$router.push({
        name: 'history-detail-reward-adhoc',
        query: { id: item.id },
      });
    },
    getActionName(id) {
      return this.listAction.find((item) => item.id === id).name;
    },
    fetchHistory: async function () {
      this.page = this.$route.query.page || 1;

      const rewardAdhocIdQuery = this.$route.query.rewardAdhocId;

      const newQuery = { ...this.$route.query };
      delete newQuery.rewardAdhocId;
      this.$router.replace({ query: newQuery });

      if (rewardAdhocIdQuery) {
        this.rewardAdhocId = rewardAdhocIdQuery;
      }

      if (!this.search) {
        this.search = '';
      }

      const params = {
        page: this.page,
        limit: 10,
      };

      if (this.rewardAdhocId) params.rewardAdhocId = this.rewardAdhocId;
      if (this.rewardAdHocName) params.rewardAdHocName = this.rewardAdHocName;

      ApiService.setHeader();
      ApiService.query('/reward-adhoc/histories', { params }).then(
        (response) => {
          const { result, totalPages, currentPage, totalItems } =
            response.data.data;

          this.totalPages = totalPages;
          this.currentPage = currentPage;
          this.totalItems = totalItems;
          this.histories = result;
        },
      );
    },
  },
};
</script>

<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 500;
  background: white;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
